import React from "react"
import { Link } from "gatsby"
import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import PageHeader from "../components/PageHeader"
import Metatags from "../components/metatags"
import UseSiteMetadata from "../components/UseSiteMetadata"

const IndexES = ({ pageContext, location }) => {
  const { siteUrl } = UseSiteMetadata()
  const fullUrl = `${siteUrl}${location.pathname}`
  const schemaData = {
    "@context": "https://schema.org",
    "@graph": [
      {
        "@context": "https://schema.org",
        "@type": "Organization",
        email: "info@netbistrot.com",
        name: "NetBistrot",
        logo: "https://netbistrot.com/netbistrot-icon-540.png",
      },
      {
        "@type": "WebSite",
        "@id": "https://netbistrot.com/es/",
        url: "https://netbistrot.com/es/",
        name: "NetBistrot",
        inLanguage: "es-ES",
      },
    ],
  }
  return (
    <>
      <Metatags
        title="NetBistrot - Digital agency"
        description="Grande comunicación para negocios pequeños."
        url={fullUrl}
        image="https://netbistrot.com/netbistrot-icon-540.png"
        keywords="web design, web studio, serverless, user experience, social media, graphic design"
        language={pageContext.langKey}
        schemaData={schemaData}
      />
      <NavBar />
      <div className="wrapper z-0">
        <PageHeader />
        <div className="main mx-2">
          <div className="section section-typo">
            <div className="mx-auto max-w-4xl">
              <div className="text-center ">
                <span className="text-4xl">
                  Grande comunicación para negocios pequeños.
                </span>
              </div>
              <div className="h-8" />
              {/* <div className="grid grid-cols-1 md:grid-cols-12 gap-4">
                <div className="col-span-2" />
                <div className=" col-span-8">
                  <p className="text-2xl">Lo que hacemos:</p>
                  <div className="h-4" />
                  <p className="text-2xl pb-4">
                    Sitios web de alto rendimiento
                  </p>
                  <p className="text-2xl">Carga de páginas súper rápidas</p>
                  <p className="text-xl pl-6">mejor experiencia de usuario</p>
                  <p className="text-xl pl-6">
                    mejor participación del usuario
                  </p>
                  <p className="text-xl pl-6">mejor posicionamiento SEO</p>
                  <div className="h-4" />
                  <p className="text-2xl">Bajo consumo eléctrico</p>
                  <p className="text-xl pl-6">responsabilidad ambiental</p>
                  <div className="h-4" />
                  <p className="text-2xl">Menores costes de mantenimiento</p>
                  <div className="h-4" />
                  <p className="text-2xl">
                    Optimización de mecanismo de búsqueda
                  </p>
                  <p className="text-xl pl-6">mejor posicionamiento SEO</p>
                  <div className="h-4" />
                  <p className="text-2xl">Escalabilidad</p>
                  <p className="text-xl pl-6">
                    acceso garantizado incluso durante los picos de demanda
                  </p>
                  <div className="h-8" />
                  <Link
                    className="btn-icon rounded text-lg text-white bg-blue-600 hover:bg-blue-800 focus:outline-none focus:ring px-4 py-2 font-semibold"
                    to="/es/static-websites/"
                  >
                    Cómo lo hacemos?
                  </Link>
                </div>
              </div> */}
            </div>
          </div>
          <div className="h-20" />
        </div>
        <Footer lang="pt" />
      </div>
    </>
  )
}

export default IndexES
